import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ChoiceGroup, ComboBox, PrimaryButton } from "@fluentui/react";
import useUser from "../../hooks/useUser";
import { APIErrorHandler } from "../../actions/APIErrorHandler";
import SelectButtonPanel from "./SelectButtonPanel";
import SelectLawReviews from "./SelectLawReviews";
import axios from "axios";
import { mTypes } from "../../utils";
import Authors from "./Authors";
import { TextField } from "@fluentui/react";

const minTitleLength = 6;

const getAuthor = (name, email, user) => {
  let a = {};
  if (!name && !user.scholarsift) return [];

  if (name) {
    a.name = name;
    a.email = email;
    a.contact = false;
  } else if (user.scholarsift) {
    a.name = user.scholarsift.name;
    a.email = user.scholarsift.email;
    a.contact = false;
  }
  return [a];
};

const Submit = (props) => {
  let { id } = useParams();
  const location = useLocation();
  const { user, currentUser, saveUser } = useUser();
  const [file, setFile] = useState();
  const [abstract, setAbstract] = useState();
  const [cv, setCV] = useState();
  const [cover, setCover] = useState();
  const [title, setTitle] = useState("");
  const [lawreviews, setLawReviews] = useState();
  const [targets, setTargets] = useState(id ? [atob(id)] : []);
  const [demos, setDemos] = useState();
  const [authors, setAuthors] = useState(
    getAuthor(props.name, props.email, currentUser)
  );
  const [abstractText, setAbstractText] = useState("");
  const [mOps, setMops] = useState([...mTypes]);
  const [mType, setMType] = useState();
  const [confirm, setConfirm] = useState(props.name ? true : false);
  const [emailAll, setEmailAll] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [progress, setProgress] = useState(0);

  const FetchReviews = (retries) => {
    axios
      .get("/api/reviews", {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((response) => {
        let revs = [];
        response.data.forEach((v) => {
          revs.push({ key: v, text: v });
        });
        setLawReviews(revs);
      })
      .catch((err) => {
        APIErrorHandler(err, user, saveUser, (v) => {
          let delay = retries ? (retries < 6 ? retries + 1 : retries) : 1;
          setTimeout(FetchReviews(delay), delay * 5000);
        });
      });
  };

  useEffect(() => {
    if (!lawreviews) FetchReviews();
  });

  useEffect(() => {
    setAuthors(getAuthor(props.name, props.email, currentUser));
  }, [props.name, props.email, currentUser]);

  const processFile = (tgt, file) => {
    if (tgt === "file") {
      let name = file.name;
      if (name.indexOf(".") > -1) name = name.substr(0, name.lastIndexOf("."));
      setTitle(name);
      setFile(file);
    } else if (tgt === "cv") setCV(file);
    else if (tgt === "cover") setCover(file);
    else if (tgt === "abstract") setAbstract(file);
  };

  const selectFile = (tgt) => {
    if (tgt === "file") {
      setAuthors(getAuthor(props.name, props.email, currentUser));
      setTargets(id ? [atob(id)] : []);
      setFile(undefined);
      setTitle("");
      setDemos(undefined);
    } else if (tgt === "cv") setCV(undefined);
    else if (tgt === "cover") setCover(undefined);
    else if (tgt === "abstract") setAbstract(undefined);

    let input = document.createElement("input");
    input.type = "file";
    input.accept =
      ".doc,.docx,.pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf";
    input.onchange = (e) => {
      processFile(tgt, e.target.files[0]);
    };
    input.click();
  };

  const mChange = (_ev, option, _index, value) => {
    if (!option) {
      if (value) {
        setMops([...mOps, { key: value, text: value }]);
        setMType(value);
      }
    } else setMType(option.key);
  };

  const submit = (retries) => {
    if (retries && retries > 5) return;

    //Meta Data
    let md = {
      title: title,
      profile: props.account ? props.account : user.current.scholarsift.profile,
      targets: targets,
      demos: demos,
      authors: authors,
      abstractText: abstractText,
      type: mType,
      confirm: confirm,
      emailAll: emailAll,
    };

    //Files
    let fd = new FormData();
    fd.append("file", file);
    if (cv) fd.append("cv", cv);
    if (cover) fd.append("cover", cover);
    if (abstract) fd.append("abstract", abstract);
    fd.append("metadata", JSON.stringify(md));

    //Submit
    axios
      .post("/api/submit", fd, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
        onUploadProgress: (p) => {
          setProgress(Math.round((100 * p.loaded) / p.total));
        },
      })
      .then((results) => {
        console.log("Submitted " + file.name);
      })
      .catch((error) => {
        APIErrorHandler(error, user, saveUser, () => {
          submit(retries ? retries + 1 : 1);
        });
      });
  };

  const clear = () => {
    setFile(undefined);
    setAbstract(undefined);
    setCV(undefined);
    setCover(undefined);
    setTitle("");
    setLawReviews(undefined);
    setTargets(id ? [Buffer.from(id, "base64")] : []);
    setDemos(undefined);
    setAuthors(getAuthor(props.name, props.email, currentUser));
    setAbstractText("");
    setMops([...mTypes]);
    setMType(undefined);
    setConfirm(props.name ? true : false);
    setEmailAll(true);
    setToggle(false);
    setProgress(0);
  };

  if (location.pathname.indexOf("manage") > -1 && props.account === undefined)
    return <div>Create an account</div>;

  if (toggle && mType !== undefined && demos !== undefined)
    return (
      <SelectLawReviews
        authors={authors}
        clear={clear}
        confirm={confirm}
        lawReviews={lawreviews}
        name={props.name}
        progress={progress}
        submit={submit}
        targets={targets}
        setConfirm={setConfirm}
        setEmailAll={setEmailAll}
        setTargets={setTargets}
        setToggle={() => {
          setToggle(false);
        }}
      />
    );

  return (
    <div
      style={{
        marginTop: props.TopMargin,
        justifyContent: "center",
        marginBottom: 100,
      }}
    >
      <div className="submit-container">
        <div style={{padding:10}}>&nbsp;</div>
        <h1 style={{backgroundColor:"var(--blue)", color:"white", borderRadius:"5px", margin:"auto",padding:7, width:"fit-content", boxShadow: "0 3px 10px 0 rgba(59,64,66,0.5)" }}>Submit a Paper</h1>
        <div className="submit-sub-text">
          Drag and drop files to upload or use the buttons below.
        </div>
        <div className="submit-paper">
          <div className="submit-paper-group">
            <div className="submit-paper-panel">
              <SelectButtonPanel
                select="Select Manuscript"
                target="file"
                label={file ? file.name : "(Required)"}
                selectFile={selectFile}
                processFile={processFile}
              />
              <SelectButtonPanel
                select="Select CV"
                target="cv"
                label={cv ? cv.name : "(Optional)"}
                selectFile={selectFile}
                processFile={processFile}
              />
              <SelectButtonPanel
                select="Select Cover"
                target="cover"
                label={cover ? cover.name : "(Optional)"}
                selectFile={selectFile}
                processFile={processFile}
              />
            </div>
            <div className="submit-paper-item">
              <Authors authors={authors} /* setAuthors={setAuthors} */ />
              <TextField
                label="Title"
                value={title}
                onChange={(_, newValue) => {
                  setTitle(newValue);
                }}
                required
                onGetErrorMessage={(v) =>
                  v.length < minTitleLength
                    ? v.length === 0
                      ? file
                        ? "Please enter a title"
                        : ""
                      : "Please complete the title"
                    : ""
                }
              />
            </div>
          </div>
        </div>
        {title.length >= minTitleLength && file && (
          <div className="submit-target">
            <div className="submit-target-full">
              <ChoiceGroup
                required
                className="submit-paper-item"
                label="Submit Demographics"
                selectedKey={demos}
                options={[
                  { key: "yes", text: "Yes" },
                  { key: "no", text: "No" },
                ]}
                onChange={(_, option) => {
                  setDemos(option.key);
                }}
              />
              <ComboBox
                className="submit-paper-item"
                label="Manuscript Type"
                options={mOps}
                onChange={mChange}
                autoComplete="on"
                allowFreeform={true}
                selectedKey={mType}
                required
              />
            </div>
            {mType !== undefined && demos !== undefined && (
              <PrimaryButton
                text="Select Law Reviews"
                onClick={() => {
                  setToggle(!toggle);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Submit;
